<template>
  <b-container fluid>
       <b-row style="line-height: 20px">
            <template>
                <b-row>
                <table class="table table-bordered" style="width: 100%; padding:10px">
                <thead>
                  <tr>
                    <th>{{$t('tradeTariffHsCode.hs_code')}}</th>
                    <th>{{$t('externalTradeTraiff.item_description')}}</th>
                    <th v-if="importOrExport == 1">{{$t('tradeTariffHsCode.import_duty')}}</th>
                    <th v-if="importOrExport == 2">{{$t('tradeTariffHsCode.export_duty')}}</th>
                    <th>{{$t('globalTrans.attachment')}}</th>
                    <th>{{$t('globalTrans.action')}}</th>
                  </tr>
                </thead>
                <tbody>
                    <template v-if="hsCodeItems.length > 0">
                        <tr v-for="(hsCode, index) in hsCodeItems" :key="index">
                        <td>{{ hsCode.hs_code }}</td>
                        <td>{{ hsCode.description }}</td>
                        <td v-if="importOrExport == 1">{{ hsCode.import_duty }}</td>
                        <td v-if="importOrExport == 2">{{ hsCode.export_duty }}</td>
                        <td v-if="importOrExport == 1">
                            <span v-if="hsCode.import_attachment !== null"><a target="_blank" class="btn btn-primary mr-2"
                                            :href="tradeTariffServiceBaseUrl+'download-attachment?file='+hsCode.import_attachment"
                                            title="Import Attachment"><i class="ri-file-download-line"></i>&nbsp;</a></span>
                                <span v-else>N/A</span>
                        </td>
                        <td v-if="importOrExport == 2">
                            <span v-if="hsCode.export_attachment !== null"><a target="_blank" class="btn btn-primary mr-2"
                                            :href="tradeTariffServiceBaseUrl+'download-attachment?file='+hsCode.export_attachment"
                                            title="Export Attachment"><i class="ri-file-download-line"></i>&nbsp;</a></span>
                                <span v-else>N/A</span>
                        </td>
                        <td>
                          <b-button v-b-modal.modal-details-second variant=" iq-bg-success mr-1" size="sm" @click="details(hsCode)" class="action-btn edit" :title="$t('globalTrans.details')"><i class="ri-eye-fill"></i></b-button>
                        </td>
                        </tr>
                    </template>
                </tbody>
                </table>
                </b-row>
            </template>
    </b-row>
    <b-modal id="modal-details-second" size="lg" :title="viewTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
     <Details :items="detailsItems" :key="viewitemId"/>
    </b-modal>
  </b-container>
</template>
<style scoped>
th {
    background-color: #007bff;
    color: white;
}
</style>
<script>
import Details from './Details.vue'
import { tradeTariffServiceBaseUrl } from '@/config/api_config'
export default {
    props: ['hsCodeItems', 'importOrExport'],
    components: {
        Details
    },
    created () {
    },
    mounted () {
        // core.index()
    },
    data () {
        return {
              tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl,
              detailsItems: [],
              viewitemId: 0
        }
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        },
        viewTitle () {
         return this.$t('tradeTariffHsCode.hs_code') + ' ' + this.$t('globalTrans.details')
        }
    },
    methods: {
        details (codeDetails) {
            this.detailsItems = codeDetails
            this.viewitemId = codeDetails.id
        }
    }
}
</script>
